import axios from "axios";
import router from "../router";

// const SERVER_ROOT_URL = "/cpis/";
axios.defaults.baseURL = process.env.VUE_APP_BASIC_ApiUrl;
axios.defaults.timeout = 10000;
axios.defaults.withCredentials = true;

axios.defaults.headers = {
  post: {
    "Content-Type": "application/json;charset=utf-8",
    version: "1.0"
  },
  get: {
    "Content-Type": "application/json;charset=utf-8",
    version: "1.0"
  }
};

//请求拦截器
axios.interceptors.request.use(
  function(config) {
    if (config.method === "post" && null != config.data) {
      if (config.headers["Content-Type"]) {
        if (
          config.headers["Content-Type"].toLowerCase() ===
            "application/json;charset=utf-8" ||
          config.headers["Content-Type"].toLowerCase() === "application/json"
        ) {
          config.data = JSON.stringify(config.data);
        }
      } else {
        config.data = JSON.stringify(config.data);
      }
    }
    if (localStorage.getItem("cpis-web_token")) {
      let token = localStorage.getItem("cpis-web_token");
      config.headers["Authorization"] = token
    }
    config.headers["Resource"] = 'CUSTOMER'
    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);

//响应拦截器
axios.interceptors.response.use(
  function(response) {
    if (response.headers.authorization) {
      localStorage.setItem("cpis-web_token", response.headers.authorization);
    }
    return response.data;
  },
  function(error) {
    if(undefined != error.response && null != error.response){
      if(undefined != error.response.status && null != error.response.status){
        if (error.response.status == 401) {
          localStorage.removeItem("cpis-web_token");
          localStorage.removeItem("cpis-web_userInfo");
          router.replace({
            path: "/login",
            query: { redirect: router.currentRoute.fullPath }
          });
        }else if (undefined != error.response.data && null !== error.response.data) {
          return Promise.reject(error.response.data.msg.message);
        } else {
          router.replace({
            path: "/login",
            query: { redirect: router.currentRoute.fullPath }
          });
          return Promise.reject(error.message);
        }
      }else {
        if (undefined != error.response.data && null !== error.response.data) {
            return Promise.reject(error.response.data.msg.message);
        }else{
            return Promise.reject(error.message);
        }
      }
    } else {
      if (error.message.indexOf("timeout") >= 0) {
        return Promise.reject("传输故障，请稍后再试");
      } else if (error.message.indexOf("Network Error") >= 0) {
        return Promise.reject("网络连接错误");
      } else if (error.message.indexOf("404") >= 0) {
        return Promise.reject("未找到相应接口");
      }
    }
  }
);

export default axios